<script setup>
import { format } from "date-fns";
import { defineProps, ref, watch, onMounted, computed, reactive } from 'vue';
import { en as i18nEn } from './i18n';
import { IconTrash } from '@tabler/icons-vue'

function t(key) {
  return i18nEn[key] || key;
}

const props = defineProps(['class']);
const REMINDER_LIMIT = 10;

const reminderLabels = [
  t('first_reminder'),
  t('second_reminder'),
  t('third_reminder'),
  t('fourth_reminder'),
  t('fifth_reminder'),
  t('sixth_reminder'),
  t('seventh_reminder'),
  t('eighth_reminder'),
  t('ninth_reminder'),
  t('tenth_reminder'),
];

const reminderOptions = reactive([
  { value: 'none',          label: 'None' },
  { value: '1_hour',        label: '1 Hour' },
  { value: '2_hour',        label: '2 Hours' },
  { value: '4_hour',        label: '4 Hours' },
  { value: '8_hour',        label: '8 Hours' },
  { value: '12_hour',       label: '12 Hours' },
  { value: '24_hour',       label: '24 Hours' },
  { value: '2_day',         label: '2 Days' },
  { value: '4_day',         label: '4 Days' },
  { value: '8_day',         label: '8 Days' },
  { value: '15_day',        label: '15 Days' },
  { value: 'custom_hours',  label: 'Custom Hours' },
  { value: 'custom_days',   label: 'Custom Days' },
]);

const reminderRows = reactive([
  // example values:
  //  { optionValue: '4_hour', unit: 'day', custom_value: '' },
  //  { optionValue: 'custom_hours', unit: 'hours', custom_value: '' },
  { optionValue: 'none', unit: '', value: '' },
]);

function addReminderRow(e) {
  e.preventDefault();
  reminderRows.push({ optionValue: 'none', value: '' });
}

function changeReminderRows(e, reminderRowIdx) {
  const optionValue = e.target.value;
  reminderRows[reminderRowIdx].optionValue = optionValue;

  if (optionValue === 'none') {
    reminderRows[reminderRowIdx].value = '';
    reminderRows[reminderRowIdx].unit = '';
    return;
  }

  reminderRows[reminderRowIdx].unit = optionValue.includes('day') ? 'day' : 'hour';

  if (optionValue.includes('custom')) {
    reminderRows[reminderRowIdx].value = 0;
    return;
  }
  reminderRows[reminderRowIdx].value = optionValue.split('_')[0];
}

const reminderRowValueSanitized = computed(() => {
  return JSON.stringify(
    reminderRows.map(row => {
      return {
        value: row['value'],
        unit: row['unit'],
      };
    })
  );
});
</script>

<template>
  <div :class="[props.class]">
    <h3 class="text-lg font-semibold">Reminders</h3>
    <p class="text-slate-500 text-sm">
      Follow up with automatic reminders. Signer will receive emails until they sign or decline the envelope.
    </p>

    <div>
      <div
        v-for="(reminderRow, reminderRowIdx) in reminderRows"
        :key="reminderRowIdx"
        class="flex gap-3"
      >
        <label class="form-control w-1/2">
          <div class="label px-0">
            <span class="label-text">{{ reminderLabels[reminderRowIdx] }}</span>
          </div>
          <select
            class="select select-sm select-bordered max-w-xs"
            :value="reminderRows[reminderRowIdx].optionValue"
            @change="e => changeReminderRows(e, reminderRowIdx)"
          >
            <option v-for="option in reminderOptions" :value="option.value">{{ option.label }}</option>
          </select>
        </label>

        <label
          v-if="reminderRows[reminderRowIdx].optionValue === 'custom_hours' || reminderRows[reminderRowIdx].optionValue === 'custom_days'"
          class="form-control w-1/4 max-w-xs"
        >
          <div class="label px-0">
            <span class="label-text">
              Number of {{ reminderRows[reminderRowIdx].optionValue === 'custom_days' ? 'days' : 'hours' }}
            </span>
          </div>
          <input
            v-model="reminderRows[reminderRowIdx].value"
            type="number"
            placeholder="Type number here"
            class="input input-sm input-bordered w-full max-w-xs"
          />
        </label>

        <IconTrash
          v-if="reminderRows.length > 1"
          @click="reminderRows.splice(reminderRowIdx, 1)"
          class="text-red-600 hover:bg-red-200 hover:cursor-pointer rounded-full p-1 inline-block mt-auto ml-auto"
          :width="33"
          :height="33"
        />
      </div>

      <input type="hidden" name="reminder_schedules" :value="reminderRowValueSanitized">
      <button
        v-if="reminderRows.length < REMINDER_LIMIT"
        class="btn btn-xs btn-neutral text-white mt-2"
        @click="addReminderRow"
      >
        Add Reminder
      </button>
    </div>
  </div>
</template>
