<template>
  <div>
    <div class="flex items-center">
      <span class="mr-1">{{ props.title || 'Contact' }}</span>

      <IconAddressBook
        @click="isModalOpen = !isModalOpen"
        class="hover:bg-base-300 hover:cursor-pointer rounded-full p-1 inline-block"
        :width="30"
        :height="30"
      />
    </div>

    <input
      v-model="nameInput"
      type="text"
      :name="props.inputNameName"
      class="input input-sm input-bordered w-full mt-1"
      placeholder="Name"
    >

    <input
      v-model="emailInput"
      type="text"
      :name="props.inputNameEmail"
      class="input input-sm input-bordered w-full mt-1"
      placeholder="Email"
    >

    <div
      id="contactModal"
      :class="`modal fade ${isModalOpen && 'modal-open'} items-start !animate-none overflow-y-auto`"
    >
      <div class="absolute top-0 bottom-0 right-0 left-0" @click.self="isModalOpen = false">
        <div class="modal-box pt-4 pb-6 px-6 mt-12 max-h-none w-full max-w-xl">
          <div class="flex justify-between items-center border-b pb-2 mb-2 font-medium">
            <span>Select Existing Contact</span>
            <a
              href="#"
              class="text-xl"
              @click.prevent="isModalOpen = false"
            >&times;</a>
          </div>

          <input type="text" class="input input-sm input-bordered w-full mb-2" placeholder="Search" v-model="searchKey">

          <div class="relative overflow-x-auto rounded">
            <table class="w-full text-sm text-left rtl:text-right">
              <thead class="text-xs text-gray-700 uppercase bg-base-200">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Phone
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(contact, index) in allContacts"
                  :key="`contact-${index}`"
                  class="border-b hover:cursor-pointer hover:bg-base-300"
                  @click="selectContact(contact)"
                >
                  <td class="px-6 py-4">
                    {{ contact.name }}
                  </td>
                  <td class="px-6 py-4">
                    {{ contact.email }}
                  </td>
                  <td class="px-6 py-4">
                    {{ contact.phone }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { debounceFilter, watchWithFilter } from '@vueuse/core'
import { IconAddressBook } from '@tabler/icons-vue'
import { defineProps, ref, watch, onMounted, onUnmounted } from 'vue'

const props = defineProps(['title', 'input-name-name', 'inputNameEmail'])

const emailInput = ref('');
const nameInput = ref('');
const searchKey = ref('');
const isModalOpen = ref(false);
const allContacts = ref([]);

const handleEsc = (event) => {
  if (event.key === 'Escape') {
    isModalOpen.value = false
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleEsc)
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleEsc)
})

watch(isModalOpen, (newVal, oldVal) => {
  if (newVal && !oldVal && allContacts.value.length === 0) {
    getAllContacts(searchKey.value)
  }
})

watchWithFilter(
  searchKey,
  () => {
    getAllContacts(searchKey.value)
  },
  {
    eventFilter: debounceFilter(500, { maxWait: 1000 }),
  },
)

const getAllContacts = async (text) => {
  const q = text.split(/[;,]+/).pop().trim()

  const queryParams = new URLSearchParams({ q })

  try {
    const resp = await fetch('/submitters_autocomplete?' + queryParams)
    const items = await resp.json()

    allContacts.value = items
  } catch (error) {
    allContacts.value = []
  }
}

const selectContact = (contact) => {
  isModalOpen.value = false

  nameInput.value = contact.name
  emailInput.value = contact.email
}
</script>
