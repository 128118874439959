const en = {
  first_reminder: 'First Reminder',
  second_reminder: 'Second Reminder',
  third_reminder: 'Third Reminder',
  fourth_reminder: 'Fourth Reminder',
  fifth_reminder: 'Fifth Reminder',
  sixth_reminder: 'Sixth Reminder',
  seventh_reminder: 'Seventh Reminder',
  eighth_reminder: 'Eighth Reminder',
  ninth_reminder: 'Ninth Reminder',
  tenth_reminder: 'Tenth Reminder',
}

export { en };
